import { createRouter, createWebHistory } from "vue-router";

// Import skills (and insert into AppPage Routing)
import valeRoutes from "../skills/vale/router";
import termsRoutes from "../skills/terms/router";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/AppPage.vue"),
    children: [
      // Insert skill routes here!
      ...termsRoutes,
      ...valeRoutes,
      {
        path: "dashboard",
        component: () => import("../components/DashboardComponent.vue"),
      },
      {
        path: "/welcome",
        name: "WelcomePage",
        component: () => import("../components/WelcomeContent.vue"),
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: () => import("../components/ProfileComponent.vue"),
      },
      {
        path: "admin",
        name: "AdminPage",
        component: () => import("../components/admin/AdminComponent.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("../views/LoginPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
