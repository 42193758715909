import Swal, { SweetAlertIcon } from "sweetalert2";

import { Semiant } from "./index";
import { Environment } from "./semiantEnv";

export class SemiantStore {
  constructor(semiant: Semiant) {
    this.semiant = semiant;
  }

  private semiant: Semiant; // Set by constructor

  public getVersion() {
    return require("../../package.json").version;
  }

  /**
   * For convenience, so that we can access the environment from the injected semiant object
   */
  env = Environment;
  // Caching
  private authenticated: boolean = false;
  private user: any;

  async isAuthenticated() {
    if (!this.authenticated) {
      const instance = await this.semiant.getAxiosInstance();
      try {
        const response = await instance.get("/auth/loginstate");
        this.authenticated = response.status === 200;
      } catch (e) {
        console.log("Caught error, cant authenticate");
      }
    }
    return this.authenticated;
  }

  public getLoginURL() {
    return this.env.backend + "/auth/login";
  }

  public getSignupURL() {
    return this.env.backend + "/auth/login/signup";
  }

  /**
   * Calls the backend logout URL with the authentication token.
   */
  public async logout() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/auth/logout", { maxRedirects: 0 });
    return response;
  }

  /**
   * Retrieves the Logout URL
   */
  public async getLogoutURL() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/auth/logout");
    return response.data;
  }

  /** Returning a promise */
  public async resendVerificationEmail() {
    console.log("Resending verification...");
    const instance = await this.semiant.getAxiosInstance();
    return await instance.post("/auth/resend");
  }

  public async getUser() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/user");
    return response.data;
  }

  public async setAdmin(userId: String, isAdmin: Boolean) {
    const instance = this.semiant.getAxiosInstance();
    const response = await instance.put("/admin/semiant-admin/" + userId, {
      "semiant-admin": isAdmin,
    });
    return response.data;
  }

  public getAxiosInstance() {
    return this.semiant.getAxiosInstance();
  }

  public async getUsers() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/admin/users");
    return response.data;
  }

  async getDatasources() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/datasources");
    return response.data;
  }

  async getDatasource(id: String) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/datasource/" + id);
    return response.data;
  }

  public async createUser() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.put("/user");
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  }

  public async updateUser(userId: String, payload: String) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.put("/user/" + userId, payload);
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  }

  public async getSystemConfiguration() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/admin/configuration");
    return response.data;
  }

  public async applyLicense(payload: object) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.put("/admin/license", payload);
    return response.data;
  }

  async getGlossaries() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/glossaries");
    return response.data;
  }

  async getGlossaryTable(payload: object) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.put("/glossarytable", payload);
    return response.data;
  }

  async getGlossary(glossaryId: string) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/glossary/" + glossaryId);
    return response.data;
  }

  async getGloss(glossId: string) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/gloss/" + glossId);
    return response.data;
  }

  async deleteGlossary(glossaryId: string) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.delete("/glossary/" + glossaryId);
  }

  async createGlossary(payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.post("/glossary", payload);
  }

  async updateGlossary(glossaryId: String, payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.put("/glossary/" + glossaryId, payload);
  }

  async updateGlossDefinition(glossId: String, payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.put("/gloss/" + glossId + "/edit", payload);
  }

  async createGloss(glossaryId: String, payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.post("/gloss/" + glossaryId, payload);
  }

  async glossFP(glossId: String) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.put("/gloss/" + glossId + "/mark-fp");
  }

  async glossDelete(glossId: String) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.delete("/gloss/" + glossId);
  }

  async generateGlossary(glossaryId: string) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.put("/glossary/" + glossaryId + "/gloss-generation");
  }

  async createDatasourceText(payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.post("/datasource/text", payload);
  }

  async createDatasourceCsv(payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    return await instance.post("/datasource/csv", payload);
  }

  async createDatasourceOGC(payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    const result = await instance.post("/datasource/ogc", payload);
    return result;
  }

  async updateDatasource(id: String, payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.put("/datasource/" + id, payload);
  }

  async deleteDatasource(datasourceId: string) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.delete("/datasource/" + datasourceId);
  }

  async attachDatasources(glossaryId: string, datasources: Array<string>) {
    const instance = await this.semiant.getAxiosInstance();
    await instance.put("/glossary/" + glossaryId + "/datasource", datasources);
  }

  public async getOrganizations() {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.get("/admin/organizations");
    return response.data;
  }

  public async createOrganization(payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.post("/admin/organizations", payload);
    return response.data;
  }

  public async updateOrganization(organizationId: string, payload: any) {
    const instance = await this.semiant.getAxiosInstance();
    const response = await instance.put(
      "/admin/organizations/" + organizationId,
      payload
    );
    return response.data;
  }

  showToast(msg: string, messagetype: SweetAlertIcon = "error") {
    Swal.fire({
      title: msg,
      icon: messagetype,
      toast: true,
      position: "bottom",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }

  /**
   * Shows a confirm dialog and return true if confirmed.
   */
  async confirm(t: any) {
    const result = await Swal.fire({
      title: t("general.dialg.confirm.title"),
      text: t("general.dialg.confirm.text"),
      showCancelButton: true,
      confirmButtonColor: "#7eb13d", // FIXME Use primary instead
      confirmButtonText: t("general.dialg.confirm.confirm"),
      cancelButtonText: t("general.dialg.confirm.cancel"),
    });
    return result.isConfirmed;
  }

  /**
   * Generates a random color from a string. The same string will always result in the same color.
   */

  stringToColor(str: String) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = Math.abs(str.charCodeAt(i) + ((hash << 5) - hash));
    }
    let color = "hsl(" + (hash % 360) + ", ";
    hash = (hash >> 8) & 0xff;
    color += (hash % 50) + 50 + "%, 50%)";
    return color;
  }

  stringToColorChip(str: String) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = Math.abs(str.charCodeAt(i) + ((hash << 5) - hash));
    }
    const color = "hsl(" + (hash % 360) + ", 100%, 25%";
    return color;
  }
}
