import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createSemiant } from "./semiant";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import "./assets/css/semiant.css";
async function init() {
  const semiant = createSemiant();

  // i18n
  const i18n = createI18n({
    locale: "en",
    legacy: false,
    warnHtmlMessage: false,
    globalInjection: true,
    messages: {
      en: {
        ...require("./locales/en.json"),
        ...require("./skills/terms/locales/en.json"),
      },
    },
  });

  // Add programmatic Guards to the router
  router.beforeEach(async (to) => {
    if ("access_token" in to.query && to.query.access_token) {
      console.log("Replacing access token: " + to.query.access_token);

      // Store the token for Axios calls
      localStorage.setItem("access_token", to.query.access_token + "");

      window.history.replaceState({}, document.title, window.location.pathname); // URL Cleanup

      // TODO Send the token to the browser extension, instead from DashboardBrowserExtension
      // What I do now works, but here would be more logical
    }

    // Redirect to login if not logged in.
    if (to.path !== "/login") {
      const authenticated = await semiant.isAuthenticated();
      if (!authenticated) return { path: "/login" };
    }
  });

  const semiantTheme = {
    dark: false,
    colors: {
      primary: "#7eb13d",
      secondary: "#2c3e50",
    },
  };

  const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: "semiantTheme",
      themes: {
        semiantTheme,
      },
    },
  });

  const app = createApp(App);
  library.add(faLink, faUser, faPowerOff);

  // Inject Semiant
  app.provide("Semiant", semiant);

  app
    .use(router)
    .use(i18n)
    .use(vuetify)
    .use(VueSweetalert2)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
}

init();
