export default [
  {
    path: "terms",
    name: "terms",
    component: () => import("./TermsComponent.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId",
    name: "termsGlossaryView",
    component: () => import("./GlossaryView.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId/edit",
    name: "termsGlossaryEdit",
    component: () => import("./GlossaryEdit.vue"),
  },
  {
    path: "terms/glossaries/new",
    name: "termsGlossaryNew",
    component: () => import("./GlossaryEdit.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId/glosses/:glossId",
    name: "termsGlossView",
    component: () => import("./GlossView.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId/glosses/:glossId/edit",
    name: "termsGlossEdit",
    component: () => import("./GlossEdit.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId/glosses/new",
    name: "termsGlossNew",
    component: () => import("./GlossEdit.vue"),
  },
  {
    path: "terms/glossaries/:glossaryId/glosses/csv",
    name: "termsCsvImport",
    component: () => import("./GlossCsvImport.vue"),
  },
];
