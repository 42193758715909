/**
 * This file manages the Semiant state.
 */
import { SemiantStore } from "./semiantStore";
import axios from "axios";

export class Semiant {
  semiantStore = new SemiantStore(this);

  /* Gets an instance lazily and updates the cedentials if necessary */
  public getAxiosInstance() {
    // if (this.axiosInstance !== undefined) return this.axiosInstance

    // Build the instance
    const options = {
      baseURL: this.semiantStore.env.backend,
      withCredentials: true,
    };
    const axiosInstance = axios.create(options);

    // Add a request interceptor to add access token
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        // Do something with request error
        console.error(error);
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  /**
   * Helper that returns the ID from Mongo-based JSON structures. This is a workaround for
   * https://jastram.jamacloud.com/perspective.req#/items/19259?projectId=109
   */
  getMongoId(json: any): String {
    return json._id.$oid;
  }
}

export function createSemiant(): SemiantStore {
  const semiant = new Semiant();
  return semiant.semiantStore;
}
