/**
 * The following code deals with the application configuration. In development, Vue picks up values from
 * .env.development. But in production, environment variables are not carried through from the container
 * to the application.
 *
 * A crude but effective workaround is to perform a global search and replace of all Javascript files,
 * as documented here:
 *
 * https://stackoverflow.com/questions/59722631/passing-environment-variables-at-runtime-to-vue-js-application-with-docker-compo
 *
 * Key takeaway:
 * - All environment variables must not only be listed here, but also in
 *   /nginx/entrypoint.sh
 * - The names to be replaced start with ENV_! As we do a global replace, it's crucial that the strings to be
 *   replaced don't appear anywhere in the code.
 */
export const Environment = {
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID
    ? process.env.VUE_APP_AUTH0_CLIENT_ID
    : "ENV_VUE_APP_AUTH0_CLIENT_ID",

  domain: process.env.VUE_APP_AUTH0_DOMAIN
    ? process.env.VUE_APP_AUTH0_DOMAIN
    : "ENV_VUE_APP_AUTH0_DOMAIN",

  backend: process.env.VUE_APP_SEMIANT_BACKEND
    ? process.env.VUE_APP_SEMIANT_BACKEND
    : "ENV_VUE_APP_SEMIANT_BACKEND",
};
